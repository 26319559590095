.GalleryItem {
  max-width: 400px;
  position: relative;
  margin: 0 100px;
}

.GalleryItem .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-flow: wrap;
  margin-bottom: 8px;
}

.GalleryItem .title.clickable:hover {
  color: var(--accent-color);
  cursor: pointer;
}

.GalleryItem .title .header {
  font-size: 36px;
  margin: 0;
  font-weight: lighter;
}

.GalleryItem .title p {
  margin: 0;
  font-size: 26px;
  font-weight: lighter;
  font-style: italic;
}

.GalleryItem video {
  /* helps with the sizing when loading, maybe do someting more dynamic */
  width: 400px;
  height: 400px;
}

.GalleryItem .attributes {
  display: flex;
  text-transform: uppercase;
  font-style: italic;
}

.GalleryItem .attributes.statue {
  position: absolute;
  top: 300px;
  left: -60px;
}

.GalleryItem .attributes.objects {
  position: absolute;
  top: 100px;
  right: -130px;
}

.GalleryItem .placeholder {
  width: 400px;
  height: 400px;
}

.GalleryItem .color-00 {
  background-color: rgb(255 210 187);
}

.GalleryItem .color-01 {
  background-color: rgb(228 204 245);
}

.GalleryItem .color-02 {
  background-color: rgb(229 189 210);
}

.GalleryItem .color-03 {
  background-color: #dddcde;
}

.GalleryItem .color-04 {
  background-color: rgb(33 32 33);
}

@media only screen and (max-width: 480px) {
  .GalleryItem .attributes.statue {
    bottom: 15px;
    left: 15px;
    top: unset;
  }

  .GalleryItem .attributes.objects {
    top: 50px;
    right: 10px;
  }

  .GalleryItem .title {
    padding-left: 5%;
  }

  .GalleryItem .title .header {
    display: none;
  }
}
