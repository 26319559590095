.StatueFilter {
  display: flex;
  align-items: flex-end;
}

.StatueFilter h4 {
  margin: 0;
}

.StatueFilter .active-item {
  color: var(--accent-color);
}

.StatueFilter .list-item {
  text-transform: uppercase;
  font-style: italic;
  font-size: 12px;
}

.StatueFilter .list-item:hover {
  color: var(--accent-color);
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .StatueFilter {
    display: none;
  }
}
