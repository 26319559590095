.Dropdown {
  position: relative;
  display: inline-block;
}

.Dropdown .anchor > * {
  margin: 0;
  display: inline-block;
}

.Dropdown:hover .anchor {
  background-color: rgb(180, 180, 180);
}

.Dropdown .content {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: rgb(180, 180, 180);
}

.Dropdown:hover .content {
  display: block;
}

.Dropdown .content-items {
  white-space: nowrap;
}
