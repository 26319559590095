.AppShell #header {
  display: flex;
  justify-content: space-between;
  height: var(--header-height);
  width: 100%;
  padding: 10px 16px;
}

.AppShell #logo {
  font-size: 30px;
  font-weight: 500;
  margin-right: 10px;
  margin-left: 8px;
}

.AppShell #logo a {
  white-space: nowrap;
}

.AppShell #logo #icon {
  width: 32px;
  padding-right: 10px;
}

.AppShell #menu {
  white-space: nowrap;
  font-size: 22px;
  font-weight: 300;
  word-spacing: 10px;
  padding-top: 8px;
  padding-left: 30px;
}

.AppShell #dropdown {
  margin-left: 140px;
  stroke-width: 3;
  width: 40px;
  padding-top: 9px;
  padding-right: 8px;
}

.AppShell .dropdown {
  position: relative;
  display: inline-block;
}

.AppShell .dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 1;
  left: -66px;
  top: 30px;
  background-color: rgba(190, 190, 194, 0.5);
}

.AppShell .dropdown-item {
  margin: 8px 0;
}

.AppShell .dropdown:hover .dropdown-content {
  display: block;
}

.AppShell svg {
  stroke: white;
}

.AppShell a {
  color: white;
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
}

.AppShell a:hover {
  color: var(--accent-color);
  fill: var(--accent-color);
  stroke: var(--accent-color);
}

.AppShell #rightsidebar {
  transform: translateX(-55%) rotate(-90deg);
  position: absolute;
  top: 50%;
  left: 99%;
  width: fit-content;
}

.AppShell #leftsidebar {
  transform: translateX(-41%) translateY(-50%) rotate(-90deg);
  position: absolute;
  top: 50%;
}

.AppShell .match {
  color: var(--accent-color);
}

.display-none {
  display: none;
}

@media only screen and (max-width: 768px) {
  .AppShell #menu,
  .AppShell #leftsidebar,
  .AppShell #rightsidebar,
  .metamask-button {
    display: none;
  }
}

.AppShell #menu,
.AppShell #leftsidebar,
.AppShell #rightsidebar {
  display: none;
}