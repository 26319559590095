.StatueRoll {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.StatueRoll .dna {
  font-size: 28px;
  margin: 0;
}

.StatueRoll video {
  width: 100%;
  margin-bottom: 6px;
}

.StatueRoll .types ul {
  min-width: 170px;
}

.StatueRoll .types li {
  display: flex;
  justify-content: space-between;
}