
.glitch {
	/* font: {
		size: 8.125em;
		family: 'Raleway', sans-serif;
		weight: 700;
  } */
  font-size: 130px;
	text-decoration: none;
	text-transform: uppercase;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	margin: 0;
	color: #fff;
	letter-spacing: 5px;
	text-align: center;
}

.glitch:before, .glitch:after {
  display: block;
  content: attr(data-glitch);
  text-transform: uppercase;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: .8;
}

.glitch:after {
  color: #f0f;
  z-index: -2;
}

.glitch:before {
  color: #0ff;
  z-index: -1;
}

.glitch:before {
  animation: glitch .3s cubic-bezier(.25, .46, .45, .94) both infinite;
}
.glitch:after {
  animation: glitch .3s cubic-bezier(.25, .46, .45, .94) reverse both infinite;
}

@media only screen and (max-width: 400px) {
	.glitch {
		font-size: 3em;
	}
}


@keyframes glitch {
	0% {
		transform: translate(0)
	}
	20% {
		transform: translate(-5px, 5px)
	}
	40% {
		transform: translate(-5px, -5px)
	}
	60% {
		transform: translate(5px, 5px)
	}
	80% {
		transform: translate(5px, -5px)
	}
	to {
		transform: translate(0)
	}
}