.StatueDetail {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px; /* TEMPORARY */
}

.StatueDetail .content {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  padding-bottom: 80px;
}

.StatueDetail .title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-flow: wrap;
  margin-bottom: 28px;
}

.StatueDetail .title .header {
  font-size: 56px;
  margin: 0;
  font-weight: lighter;
}

.StatueDetail .title p {
  margin: 0;
  font-size: 40px;
  font-weight: lighter;
  font-style: italic;
}

.StatueDetail video {
  width: 100%;
}

.StatueDetail .attributes {
  display: flex;
  text-transform: uppercase;
  font-style: italic;
}

.StatueDetail h3 {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 6px;
}

.StatueDetail .attributes .statue {
  margin-right: 48px;
}

.StatueDetail .attributes .objects {
  display: flex;
  justify-content: space-between;
}

.StatueDetail a {
  text-decoration: underline;
}

.StatueDetail .ordinal {
  text-transform: uppercase;
}

@media only screen and (max-width: 480px) {
  .StatueDetail {
    padding: 0 20px;
    margin-top: 40px;
  }

  .StatueDetail .title .header {
    font-size: 48px;
  }

  .StatueDetail .title p {
    font-size: 28px;
  }

  .StatueDetail video {
    margin: 0 -20px;
    width: calc(100% + 40px);
  }
}
