.RollBanner {
  padding: 0 100px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.RollBanner .statues {
  width: 1100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.RollBanner .statue-placeholder {
  width: 300px;
  height: 380px;
  border: 2px dotted darkgray;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RollBanner .statue {
  width: 300px;
  height: 400px;
}

.RollBanner .statue-placeholder:hover {
  border: 2px dotted darkgray;
}
