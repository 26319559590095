.GalleryPage {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.GalleryPage .list-view {
  margin-top: 100px;
  max-width: 1200px;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

.GalleryPage .GalleryItem {
  margin-bottom: 60px;
}

.GalleryPage .StatueFilter {
  margin-top: 50px;
}
