:root {
  --accent-color: salmon;
  --header-height: 60px;
}

#root {
  height: 100vh;
}

html {
  cursor: url('./assets/cursor.png'), auto;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: rgb(190, 190, 194);
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
